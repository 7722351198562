<template>
    
    <a-drawer
        title="Camera"
        :width="820"
        closable
        :visible="visible"
        @close="cancelEdit"
        :wrapStyle="{height: 'calc(100% - 108px)',overflow: 'auto',paddingBottom: '108px'}"
    >
        <a-spin :spinning="isLoading">
            
            <a-form :form="form" layout="horizontal" hideRequiredMark :loading="true"><!-- :form="form" -->
                <a-form-item label="Salon:">
                    <a-select
                        :disabled="!!cameraData"
                        showSearch
                        placeholder="Chọn salon"
                        optionFilterProp="children"
                        style="width: 200px"
                        :filterOption="filterOption"
                        v-decorator="[
                            'salonId',
                            { rules: [{ required: true, message: 'Vui lòng chọn salon!' }] },
                        ]"
                    >
                        <a-select-option v-for="salon in getUser.listSalon" :key="salon.id" :value="salon.id">{{ salon.salonName }}</a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="Vị trí:">
                    <a-select
                        placeholder="Chọn vị trí"
                        optionFilterProp="children"
                        style="width: 200px"
                        v-decorator="[
                            'departmentId',
                            { rules: [{ required: true, message: 'Vui lòng chọn salon!' }] },
                        ]"
                    >
                        <a-select-option :value="5">Checkin</a-select-option>
                        <a-select-option :value="6">Checkout</a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="Địa chỉ IP:">
                    <a-input
                        v-decorator="['ipAddress', { rules: [{ required: true, message: 'Vui lòng nhập địa chỉ IP của Camera!' }] }]"
                    />
                </a-form-item>
                <a-form-item label="Camera số:">
                    <a-input-number
                        v-decorator="['channel', { rules: [{ required: true, message: 'Vui lòng nhập số thứ tự Camera tại salon!' }] }]"
                        :min="1" 
                        :max="20"
                    />
                </a-form-item>
                <a-form-item label="Mô tả:">
                    <a-textarea
                        v-decorator="['description']"
                    />
                </a-form-item>

                <a-form-item label="Username:">
                    <a-input
                        v-decorator="['username', { rules: [{ required: true, message: 'Vui lòng nhập username camera!' }] }]"
                    />
                </a-form-item>

                <a-form-item label="Password:">
                    <a-input
                        v-decorator="['password', { rules: [{ required: true, message: 'Vui lòng nhập mật khẩu camera!' }] }]"
                        type="password"
                    />
                </a-form-item>

                <a-form-item label="Port:">
                    <a-input-number
                        v-decorator="['port', { rules: [{ required: true, message: 'Vui lòng nhập port camera!' }] }]"
                    />
                </a-form-item>

                <a-popconfirm
                    v-if="cameraData"
                    title="Bạn có chắc chắn muốn xóa thiết bị này?"
                    @confirm="$emit('delete', cameraData)"
                    okText="Chắc chắn"
                    cancelText="Hủy"
                >
                    <a-button type="danger">Xoá</a-button>
                </a-popconfirm>
            </a-form>
        </a-spin>
        <div
            :style="{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            }"
        >
            <a-button :disabled="!editable" @click="cancelEdit" :style="{marginRight: '8px'}">
                Hủy
            </a-button>
            <a-button :disabled="!editable" @click="submit" type="primary" icon="save">Lưu lại</a-button>
        </div>
    </a-drawer>
    
</template>

<script>
import { mapGetters } from 'vuex';
import { RepositoryFactory } from "@/repository/RepositoryFactory";

const salonCameraRepository = RepositoryFactory.get("salonCamera");

export default {
    props: ['visible', 'initialData'],
    data() {
        return {
            form: this.$form.createForm(this),
            editable: true,
            isMounted: false,
            cameraData: null,
            isLoading: false
        };
    },
    computed: {
        ...mapGetters('authenticate', [
            'getUser'
        ])
    },
    mounted() {
        this.isMounted = true;
    },
    methods: {
        
        async validateInputData() {
            return new Promise((resolve, reject) => {
                this.form.validateFields((err, values) => {
                    if (!err) {
                        resolve(values);
                    } else reject(err);
                });
            })
            
        },
        async submit() {
            try {
                let data = await this.validateInputData();
                data = {
                    ...this.cameraData || [],
                    ...data
                }
                this.$emit('submit', data);
                // this.cancelEdit();
            } catch (error) {
                
                console.log('form error', error);
            }
            
        },
        cancelEdit() {
            // this.form.resetFields();
            // this.cameraData = null;
            this.$emit('cancel');
        },
        async initForm(d) {
            this.isLoading = true;
            const { data } = await salonCameraRepository.getCameraById(d.id);
            this.isLoading = false;

            this.form.setFieldsValue({
                ipAddress: data.ipAddress,
                channel: data.channel,
                description: data.description,
                username: data.username,
                password: data.password,
                port: data.port,
                salonId: data.salonId,
                departmentId: data.departmentId
            });
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        },
    watch: {
        /*initialData(data) {
            // this.form.resetFields();
            // this.cameraData = null;
            if (!data || !data.id) return;
            console.log('initial datataa', data)
            this.cameraData = data;
            
            setTimeout(() => {
                this.initForm(this.cameraData);
            }, 100)
            
            
        },*/
        visible(isVisible) {
            if (isVisible) {
                var data = this.initialData;
                if (!data || !data.id) return;
                console.log('initial datataa', data)
                this.cameraData = data;
                
                setTimeout(() => {
                    this.initForm(this.cameraData);
                }, 100)
            } else {
                this.form.resetFields();
                this.cameraData = null;
            }
        }
    }
}
</script>