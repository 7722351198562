<template>
    <a-layout-content class="Dashboard">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>Trang chủ</a-breadcrumb-item>
            <a-breadcrumb-item>Thiết bị</a-breadcrumb-item>
            <a-breadcrumb-item>Camera</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="page-container" :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px 25px', padding: '15px' }">
            <h1>Quản lý camera</h1>
            <a-button type="primary" icon="plus-circle" @click="addCamera">Thêm camera</a-button>

            <a-table
                :columns="columns"
                :rowKey="camera => camera.id"
                :dataSource="data"
                :pagination="false"
                :loading="loading"
            >
                <template slot="department" slot-scope="department">
                    {{ getPositionByDepartmentId(department) }}
                </template>

                <template slot="action" slot-scope="text, record">
                    <div class='editable-row-action'>
                        <!--<span v-if="!record.editable"  style="margin-left: 10px; display: inline-block; margin-bottom: 10px;">-->
                          <a-button @click="() => updateCamera(record)">Cập nhật</a-button>
                        <!--</span>-->
                    </div>
                </template>

                <template slot="salonName" slot-scope="salonId">
                    {{ getSalonNameById(salonId) }}
                </template>
            </a-table>
        </div>
        <CameraForm :initialData="selectedCamera" ref="questionAddForm" @cancel="handleFormCancel" @submit="handleFormSubmit" @delete="confirmDeleteCamera" :visible="visibleEdit" />
    </a-layout-content>
</template>

<script>
    import './_Camera.scss'
    import axios from 'axios';
    import { mapGetters } from 'vuex';

    import { RepositoryFactory } from "@/repository/RepositoryFactory";

    import CameraForm from '@/pages/Camera/CameraForm';

    const salonCameraRepository = RepositoryFactory.get("salonCamera");

    const columns = [
        {
            title: 'Salon',
            dataIndex: 'salonId',
            width: '20%',
            scopedSlots: { customRender: 'salonName' },
        },
        {
            title: 'Địa chỉ IP',
            dataIndex: 'ipAddress',
            width: '20%',
        },
        {
            title: 'Camera số',
            dataIndex: 'channel',
            width: '20%'
        },
        {
            title: 'Vị trí',
            dataIndex: 'departmentId',
            scopedSlots: { customRender: 'department' },
        },
        {
            title: 'Mô tả',
            dataIndex: 'description'
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
        }
    ];

  export default {
    async mounted() {
      await this.fetch();
    },
    components: {
        CameraForm
    },
    data() {
        return {
            form: this.$form.createForm(this),
            data: [],
            pagination: {},
            loading: false,
            columns,
            editable: true,
            visibleEdit: false,
            selectedCamera: {}
        };
    },
    computed: {
        ...mapGetters('authenticate', [
            'getUser'
        ])
    },
    methods: {
        async confirmDeleteCamera(camera) {
            console.log('delete camera', camera);
            await salonCameraRepository.deleteCamera(camera);
            this.visibleEdit = false;
            const cameraIndex = this.data.findIndex(i => i.id == camera.id);
            this.data.splice(cameraIndex, 1);
        },
        getPositionByDepartmentId(deparmentId) {
            if (deparmentId == 5) return 'Checkin';
            else return 'Checkout';
        },
        updateCamera(camera) {
            this.selectedCamera = camera;
            this.visibleEdit = true;
        },
        addCamera() {
            this.selectedCamera = {};
            this.visibleEdit = true;
        },
        async handleFormSubmit(data) {
            try {
                console.log('form data', data);
                data.port = parseInt(data.port);
                let camera;
                if (!data || !data.id) {
                    const response = await salonCameraRepository.addCamera(data);
                    camera = response.data;
                    this.data = [camera, ...this.data];
                } else {
                    const response = await salonCameraRepository.updateCamera(data.id, data);
                    camera = response.data;
                    const cameraIndex = this.data.findIndex(i => i.id == camera.id);
                    this.data.splice(cameraIndex, 1, camera);
                }
                
                this.visibleEdit = false;
                

            } catch (error) {
                this.$notification['error']({
                    message: 'Hệ thống',
                    description: error.message
                });
                console.log('form error', JSON.stringify(error, 0, 2));
            }
            
        },
        handleFormCancel() {
            this.visibleEdit = false;
        },
        async fetch() {
            this.loading = true;
            const { data } = await salonCameraRepository.getSalonCameras({ salonId: 0 });

            this.data = data;
            this.loading = false;
        },
        getSalonNameById(salonId) {
            const salon = this.getUser.listSalon.find(s => s.id == salonId);
            return salon.salonName;
        }
    },
  };
</script>

<style scoped>
    .editable-row-operations {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
</style>